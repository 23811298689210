<template>
  <div class="fashion-interaction__control">
    <div
      class="fashion-interaction__control-next"
      @click="$emit('change', { index: index, direction: 1 })"
    >
      <inline-svg :src="require('@/assets/svg/icon_page-arrow.svg')" />
    </div>
    <div
      class="fashion-interaction__control-prev"
      @click="$emit('change', { index: index, direction: -1 })"
    >
      <inline-svg :src="require('@/assets/svg/icon_page-arrow.svg')" />
    </div>
    <div class="fashion-interaction__control-label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
//import gsap from "gsap";

export default {
  name: "FashionInteractionControl",
  props: {
    index: undefined
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
