<template>
  <div class="fashion-interaction">
    <div class="fashion-interaction__questions" v-if="!isLoading">
      <h2 ref="h1">Are you ready?</h2>
      <h3 ref="h2">Go ahead and pick what fits for you!</h3>

      <ul>
        <li @click="onListClick(index)" v-for="(item, index) in question" :key="index" :class="{
          active: index === activeListItem || (index === 2 && isDiverse)
        }">
          {{ item }}
        </li>
      </ul>
      <Button class="button--small " @click.native="onQuestionClick"
        :class="{ 'button--disabled': activeListItem === undefined }">GO AHEAD</Button>
    </div>

    <div class="fashion-interaction__stage-wrapper">
      <div class="fashion-interaction__stage" ref="stage"></div>
      <div class="fashion-interaction__debug" ref="debug" v-if="debug" v-html="debugString"></div>

      <div class="fashion-interaction__stage-controls" :style="{ height: canvasRect.height + 'px' }">
        <FashionInteractionControl @change="onControlChange" :index="3" ref="control_1"></FashionInteractionControl>
        <FashionInteractionControl @change="onControlChange" :index="2" ref="control_2"></FashionInteractionControl>
        <FashionInteractionControl @change="onControlChange" :index="1" ref="control_3"></FashionInteractionControl>

        <div class="fashion-interaction__loader" ref="loader_animation">
          LOADING {{ this.loadProgress }}%
        </div>
        <!--
        <FashionInteractionControl @change="onControlChange" :index="4"
          >04 SHOES</FashionInteractionControl
        >-->
      </div>
    </div>

    <div class="fashion-interaction__buttons" v-if="gender">
      <Button class="button--small " @click.native="onDownloadClick" v-if="showLookbook">DOWNLOAD</Button>
      <Button class="button--small" @click.native="onBackClick">BACK</Button>
      <Button class="button--small " @click.native="onShuffleClick">SHUFFLE</Button>
    </div>
  </div>
</template>

<script>
import * as PIXI from "pixi.js";
import gsap from "gsap";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";
import Button from "../../../components/form/Button";
import FashionInteractionControl from "./FashionInteractionControl.vue";


export default {
  name: "FashionInteraction",
  mixins: [DownloadHelperMixin],
  components: { Button, FashionInteractionControl },
  props: {
    interactionData: undefined
  },

  data() {
    return {
      downloadBg: undefined,
      debug: false,
      app: undefined,
      image: undefined,
      rect: { x: 0, y: 0, height: 0, width: 0 },
      canvasRect: { x: 0, y: 0, height: 0, width: 0 },
      container: undefined,
      lookbookContainer: undefined,
      lookbookLoader: undefined,
      loader: undefined,
      hideGenitals: false,
      feigenBlatt: undefined,
      showLookbook: undefined,
      loadProgress: 0,

      level0: undefined,
      level1: undefined,
      level2: undefined,
      level3: undefined,
      level4: undefined,
      levels: 5,
      sprites: [],
      isLoading: false,
      activeListItem: undefined,
      question: ["Male", "Female", "Diverse"],
      isDiverse: false,

      level0Next: undefined,
      level1Next: undefined,
      level2Next: undefined,

      level0Prev: undefined,
      level1Prev: undefined,
      level2Prev: undefined,

      gender: undefined,

      assets: undefined,
      currentSet: [],
      debugString: undefined,
      handsVisible: undefined,
      backAsset: undefined,

      currentLevels: [-1, -1, -1],
      downloadPending: false
    };
  },
  methods: {
    loadLookbook(year) {

      if (this.lookbookLoader) this.lookbookLoader.destroy();
      if (this.lookbookContainer) this.lookbookContainer.destroy();

      const groups = ['01_Color_Card', '02_Famous_Events', '03_Famous_People', '04_Famous_Happenings', '05_Art', '06_Fabrics', '07_Interiours', '08_Muster_Farbe', '09_typo'];
      const files = ['Color_Card', 'Famous_Events', 'Famous_People', 'Famous_Happenings', 'Art', 'Fabrics', 'Interiours', 'Musterfarbe','years'];
      const years = ['', 1920, 1940, 1960, 1980, 2000, 2020];

      const fileIndex = years.indexOf(year) + 1;

      this.lookbookLoader = new PIXI.Loader(); // You can also create your own if you want
      this.lookbookContainer = new PIXI.Container();

      groups.reverse();
      files.reverse();

      groups.forEach((group, index) => {
        const file = 'assets/textures/fashion/lookbook/' + group + '/' + files[index] + (fileIndex) + '.png';
        const sprite = PIXI.Sprite.from(file);
        //sprite.alpha = 0;
        sprite.anchor.set(0.5, 0.5);
        sprite.x = 0;
        sprite.y = 200;
        sprite.scale.y = 3.4;
        sprite.scale.x = 3.4;
        sprite.alpha = 0;

        this.lookbookContainer.addChild(sprite);
        this.lookbookLoader.add(files[index] + (fileIndex), file);
      });

      /*this.lookbookLoader.onProgress.add(e => {
        
      });*/

      this.container.addChildAt(this.lookbookContainer, 0);

      this.lookbookLoader.load(() => {
        this.fadeInLookbook();
      });

    },
    fadeInLookbook() {
      this.lookbookContainer.children.forEach((child, index) => {
        gsap.to(child, { duration: 2, ease: "Expo.easeOut", delay: index * .05, pixi: { scale: 2.8 } });
        gsap.to(child, { duration: .5, alpha: 1, delay: index * .05 });
      });
      //gsap.to(this.$refs.control_1.$el,{autoAlpha:0});
      //gsap.to(this.$refs.control_2.$el,{autoAlpha:0});
      //gsap.to(this.$refs.control_3.$el,{autoAlpha:0});
    },
    onBackClick() {
      if (this.lookbookLoader) this.lookbookLoader.destroy();
      if (this.lookbookContainer) this.lookbookContainer.destroy();
      this.lookbookLoader = null;
      this.lookbookContainer = null;


      if (this.feigenBlatt) this.feigenBlatt.destroy();
      this.feigenBlatt = null;

      if (this.downloadBg) this.downloadBg.destroy();
      this.downloadBg = null;

      this.downloadPending = false;

      this.hideGenitals = false;
      this.gender = undefined;
      this.currentLevels = [-1, -1, -1];
      this.showLookbook = false;

      if (this.loader) this.loader.destroy();
      this.loader = undefined;

      /*this.sprites.forEach(item => {
          item.destroy();
        });

        this.sprites = [];*/

      gsap.to(this.$refs.control_1.$el, { autoAlpha: 0 });
      gsap.to(this.$refs.control_2.$el, { autoAlpha: 0 });
      gsap.to(this.$refs.control_3.$el, { autoAlpha: 0 });

    },
    createSprite(texture) {
      const sprite = PIXI.Sprite.from(texture);
      sprite.anchor.set(0.5, 0.5);
      sprite.alpha = 0;

      this.sprites.push(sprite);

      return sprite;
    },
    spawnSprite(obj) {
      const resources = this.loader.resources;

      let key = obj.asset;
      let r = resources[key];

      if (!obj.frontSprite) {
        const sprite = this.createSprite(r.texture);
        this.level2.addChild(sprite);

        obj.frontSprite = sprite;

        const levelContainer = this["level" + obj.level];
        levelContainer.addChild(sprite);
      }

      if (obj.back) {
        key = obj.back;
        r = resources[key];

        const back = this.createSprite(r.texture);
        this.level0.addChild(back);

        obj.backSprite = back;
      }
    },

    updateLevels(old) {
      if (old.length === 3) {
        old.forEach((item, index) => {
          if (item !== this.currentLevels[index]) {
            const keys = Object.keys(this.assets);

            const assets = [];
            keys.forEach(key => {
              assets.push(this.assets[key]);
            });

            const currentIndex = this.currentLevels[index];
            const oldIndex = old[index];

            const inLevel = assets.filter(asset => asset.group === index + 1);

            if (inLevel[oldIndex]) {
              gsap.to(inLevel[oldIndex].frontSprite, {
                alpha: 0,
                duration: 0.5
              });
              if (inLevel[oldIndex].backSprite)
                gsap.to(inLevel[oldIndex].backSprite, {
                  alpha: 0,
                  duration: 0.5
                });
            }

            gsap.to(inLevel[currentIndex].frontSprite, {
              alpha: 1,
              duration: 0.5,
              overwrite: true
            });

            if (inLevel[currentIndex].backSprite) {
              gsap.to(inLevel[currentIndex].backSprite, {
                alpha: 1,
                overwrite: true,
                duration: 0.5
              });
            }
          }
        });
      }
    },
    onControlChange(data) {
      const level = data.index;

      const allAssets = [];
      const keys = Object.keys(this.assets);

      keys.forEach(key => {
        const asset = this.assets[key];
        allAssets.push(asset);
      });

      const currentIndex = this.currentSet[level - 1].index;
      const assetsInLevel = allAssets.filter(asset => asset.level === level);
      const maxIndex = assetsInLevel.length;

      let newIndex = currentIndex + data.direction;
      if (newIndex === -1) newIndex = maxIndex - 1;
      if (newIndex < 0) newIndex = maxIndex - 1;
      if (newIndex >= maxIndex) {
        newIndex = 0;
      }

      const current = {
        asset: assetsInLevel[newIndex],
        index: newIndex
      };

      const old = [...this.currentLevels];
      this.currentLevels[level - 1] = newIndex;
      this.updateLevels(old);

      this.currentSet[level - 1] = current;
      this.updateCurrentSet();
    },
    updateScore() {
      const scores = [];
      this.currentSet.forEach(item => {
        if (item.asset) {
          const year = parseInt(item.asset.year);

          if (scores[year] === undefined) {
            scores[year] = { year: year, score: 0 };
          }

          scores[year].score++;
        }
      });

      const sorted = scores.sort((a, b) => {
        return b.score - a.score;
      });

      const fullScore = sorted[Object.keys(sorted).shift()];
      if (fullScore) {
        if (fullScore.score === 3) {
          this.showLookbook = true;
          this.loadLookbook(fullScore.year)

        } else {
          this.showLookbook = false;
        }
      }
    },

    startLoading() {
      if (this.loader) this.loader.destroy();
      this.loader = undefined;

      const loader = new PIXI.Loader(); // You can also create your own if you want

      this.loader = loader;
      this.isLoading = true;

      // Chainable `add` to enqueue a resource
      //loader.add("bunny", "data/bunny.png");

      //console.log(this.interactionData.assets.find(item => item.gender === "female").assets);

      const active = this.interactionData.assets.find(
        item => item.gender === this.gender
      );

      this.assets = {};

      if (active) {
        const stack = active.assets;

        this.sprites.forEach(item => {
          item.destroy();
        });

        this.sprites = [];

        let body = active.body;
        let shadow = active.shadow;

        this.assets.shadow = {
          asset: "shadow",
          file: shadow,
          level: 0
        };

        this.assets.body = {
          asset: "body",
          file: body,
          level: 0
        };

        loader.add("body", body);
        loader.add("shadow", shadow);
        loader.add("download_bg", "assets/textures/fashion/download_bg.jpg");
        loader.add("download_logo", "assets/textures/fashion/download_logo.png");
        loader.add("download_header", "assets/textures/fashion/title_impressions.png");


        if (this.isDiverse) {
          const feigenBlattAsset =
            "assets/textures/fashion/body_diverse/man_diverse_blatt.png";
          loader.add("feigenblatt", feigenBlattAsset);
        }

        stack.forEach(item => {
          const assets = item.stack;

          assets.forEach(asset => {
            const file =
              "assets/textures/fashion/" + item.base_path + asset.front;

            this.assets[asset.front] = {
              asset: asset.front,
              file: file,
              year: item.year,
              level: asset.level,
              hands: asset.hands,
              back: asset.back,
              group: asset.group
            };

            if (asset.back) {
              const backFile =
                "assets/textures/fashion/" + item.base_path + asset.back;
              loader.add(asset.back, backFile);
            }
            loader.add(asset.front, file);
          });
        });
      }

      loader.onProgress.add(e => {
        this.loadedProgress = e.progress;
      });

      loader.onError.add((err, loader, resource) => {
        console.log("ERROR LOADING", resource.name);
      });

      loader.load((loader, resources) => {


        const keys = Object.keys(resources);

        keys.forEach(key => {
          let r = resources[key];

          if (this.assets[key]) {
            //const sprite = PIXI.Sprite.from(r.texture);
            //sprite.anchor.set(0.5, 0.5);

            //this.sprites.push(sprite);

            const obj = this.assets[key];
            //obj.sprite = sprite;

            if (obj.level) {
              //const levelContainer = this["level" + obj.level];
              //levelContainer.addChild(sprite);
              //sprite.alpha = 0;

              this.spawnSprite(obj);
            } else {
              //shadow or body
              const sprite = PIXI.Sprite.from(r.texture);
              sprite.anchor.set(0.5, 0.5);

              this.sprites.push(sprite);

              this.level1.addChildAt(sprite, 0);
              console.log("adding:", sprite, this.container.parent);
            }
          }
        });



        this.loadProgress = 100;
        gsap.to(this.$refs.loader_animation, { y: 0, autoAlpha: 0 });
        gsap.to(this.container, { delay: .2, alpha: 1 });

        console.log("FADING", this.container);

        gsap.to(this.$refs.control_1.$el, { delay: .2, autoAlpha: 1 });
        gsap.to(this.$refs.control_2.$el, { delay: .2, autoAlpha: 1 });
        gsap.to(this.$refs.control_3.$el, { delay: .2, autoAlpha: 1 });

        gsap.delayedCall(.2, () => {
          this.shuffleAssets();
        })

      });
    },
    switchLevel(index, direction) {
      index;
      direction;
    },
    updateCurrentSet() {
      //HIDE ALL LEVELS
      let debugString = "";
      this.currentSet.forEach(set => {
        if (set) {
          //set.asset.sprite.alpha = 1;
          if (!set.asset) {
            debugString += `*** NONE ***<br>`;
          } else {
            debugString += `(${set.asset.level}) ${set.asset.asset}<br>`;
          }
        }
      });

      //TODO DIVERSE
      this.hideGenitals = false;

      if (this.gender === "diverse") {
        if (this.currentSet[0].asset.year.indexOf('_dd') > -1 && this.currentSet[1].asset.year.indexOf('_md') > -1) {
          //SCHUHE FRAU DIVERSE
          console.log("FEIGENBLATT!");
          this.hideGenitals = true;
        }
      }

      this.updateScore();
      this.debugString = this.currentLevels + "<br>" + debugString;
      this.handsVisible = this.currentSet[1].asset.hands;
    },

    onShuffleClick() {
      this.shuffleAssets();
    },
    shuffleAssets() {
      console.log("SHUFFELING ASSETS.");

      const levels = this.levels;
      let currentSet = [];

      const keys = Object.keys(this.assets);

      const assets = [];
      keys.forEach(key => {
        assets.push(this.assets[key]);
      });

      for (let i = 1; i < levels; i++) {
        const available = Array.from(assets).filter(asset => asset.level === i);

        if (available.length) {
          let rIndex = Math.floor(available.length * Math.random());
          currentSet[i - 1] = { asset: available[rIndex], index: rIndex };

          if (currentSet[i - 2]) {
            if (currentSet[i - 2].index === rIndex) {
              rIndex = Math.floor(available.length * Math.random());
              currentSet[i - 1] = { asset: available[rIndex], index: rIndex }
            }
          }

          const old = [...this.currentLevels];
          this.currentLevels[i - 1] = rIndex;
          this.updateLevels(old);
        }
      }

      this.currentSet = currentSet;
      this.updateCurrentSet();
    },
    onListClick(index) {
      this.activeListItem = index;

      if (index === 2) {
        this.isDiverse = true;
      } else {
        this.isDiverse = false;
      }
    },
    onQuestionClick() {
      //action
      let gender = "male";
      if (this.isDiverse) gender = "diverse";
      if (this.activeListItem === 1) gender = "female";

      this.loadedProgress = 0;
      gsap.set(this.$refs.loader_animation, { alpha: 1, y: 0 });

      this.gender = gender;
      this.startLoading();

      gsap.set(this.container, { alpha: 0 });

      gsap.set(this.$refs.control_1.$el, { autoAlpha: 0 });
      gsap.set(this.$refs.control_2.$el, { autoAlpha: 0 });
      gsap.set(this.$refs.control_3.$el, { autoAlpha: 0 });
    },
    onDownloadClick() {
      if (this.downloadPending) return;
      this.downloadPending = true;
      //ADDING BG

      const resources = this.loader.resources;
      const r = resources['download_bg'];

      const sprite = PIXI.Sprite.from(r.texture);
      //sprite.anchor.set(0, 0.5);


      sprite.scale.x = 2;
      sprite.scale.y = 2;

      const l = resources['download_logo'];
      const logo = PIXI.Sprite.from(l.texture);
      //logo.anchor.set(0.5, 0.5);
      logo.scale.x = .75;
      logo.scale.y = .75;

      logo.x = window.innerWidth * .5 - logo.width * .5;
      logo.y = 40;

      const h = resources['download_header'];
      const header = PIXI.Sprite.from(h.texture);
      //sprite.alpha = 0;
      header.anchor.set(0.5, 0.5);
      header.x = 0;
      header.y = 180;
      header.scale.y = 2.8;
      header.scale.x = 2.8;
      

      this.lookbookContainer.addChild(header);

      this.app.stage.addChildAt(logo, 0);
      this.app.stage.addChildAt(sprite, 0);

      gsap.delayedCall(0.15, () => {
        this.downloadPending = false;
        this.createPngTextureFromPixi(this.app, "UchroniaPuppets.png", this.app.stage);
        //
        this.lookbookContainer.removeChild(header);
        header.destroy();
        sprite.destroy();
        logo.destroy();
      });
    },
    pixiResize() {
      const rect = this.$refs.stage.getBoundingClientRect();
      this.rect = rect;

      let w = rect.width;
      let h = rect.height;

      //fixed ratio
      // resize the renderer
      this.app.renderer.view.style.width = w + "px";
      this.app.renderer.view.style.height = h + "px";
      this.app.renderer.resize(w, h);

      let containerScale = rect.width / 4000;
      if (rect.height > rect.width) {
        containerScale = rect.height / 3075;
      } else {
        if (3075 * containerScale > h) {
          containerScale = rect.height / 3075;
        }
      }

      this.container.scale.x = containerScale;
      this.container.scale.y = containerScale;

      this.container.x = w * 0.5;
      this.container.y = h * 0.48;

      this.canvasRect = {
        x: this.container.x,
        y: this.container.y,
        width: rect.width * containerScale,
        height: rect.height - 280
      };
    },
    initScene() {
      //const rect = this.$refs.stage.getBoundingClientRect();
      //Create a Pixi Application
      let app = new PIXI.Application({
        width: 700,
        height: 500,
        antialias: true,
        backgroundAlpha: 0
      });

      this.app = app;
      this.container = new PIXI.Container();
      this.app.stage.addChild(this.container);

      const levels = this.levels;
      for (let i = 0; i < levels; i++) {
        this["level" + i] = new PIXI.Container();
        this["level" + i + "Next"] = new PIXI.Container();
        this["level" + i + "Prev"] = new PIXI.Container();

        this.container.addChild(this["level" + i]);
      }

      this.$refs.stage.appendChild(app.view);
      this.animate();

      this.pixiResize();
    },

    animate() {
      //this.app.ticker.add(this.onAppTicker);
    },
    onAppTicker() { }
  },
  watch: {
    showLookbook() {
      console.log("showLookbook", this.showLookbook);
      if (!this.showLookbook) {
        if (this.lookbookLoader) this.lookbookLoader.destroy();
        if (this.lookbookContainer) this.lookbookContainer.destroy();
        this.lookbookLoader = null;
        this.lookbookContainer = null;
      }

    },
    hideGenitals() {
      if (this.hideGenitals) {
        if (!this.feigenBlatt) {
          const resources = this.loader.resources;
          let r = resources['feigenblatt'];


          const sprite = PIXI.Sprite.from(r.texture);
          sprite.anchor.set(0.5, 0.5);

          //this.sprites.push(sprite);

          this.level1.addChildAt(sprite, 3);

          this.feigenBlatt = sprite;
        }

        this.feigenBlatt.alpha = 1;
      } else {
        if (this.feigenBlatt) this.feigenBlatt.alpha = 0;
      }


    },
    handsVisible() {
      console.log("OHOOOO!");
    },
    gender() {
      if (!this.gender) {
        this.isLoading = false;
        this.gender = undefined;

        if (this.loader) this.loader.destroy();
        this.loader = undefined;
      } else {
        this.$refs.stage.appendChild(this.app.view);
      }
    },
    "$screen.lastChange"() {
      this.pixiResize();
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {
    if (this.loader) this.loader.destroy();
    this.loader = undefined;

    this.app.ticker.remove(this.onAppTicker);
    this.app.destroy();
    this.app = undefined;
  },

  mounted() {
    const rect = this.$refs.stage.getBoundingClientRect();
    this.rect = rect;
    this.initScene();

    gsap.from(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1,
      alpha: 0
    });
  }
};
</script>
